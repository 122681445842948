
import { defineComponent, ref, computed } from 'vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmBadge,
    TmModal,
    TmButton,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
    },
    fields: {
      type: Array,
      required: true,
    },
    initialFields: {
      type: Array,
    },
  },
  setup(props) {
    const tags = computed(() => {
      return props.users.map((el: any) => ({
        name: el.person.firstName + ' ' + el.person.lastName,
        avatar: el.person.avatar,
        avatarColor: el.person.avatarColor,
      }))
    })

    const state = ref(props.initialFields ? props.initialFields[0] : {})
    // const unselectedFields = computed(() => {
    //   const uidsSelected = state.value.map((el: any) => el.uid)
    //   const unselected = props.fields.filter((el: any) => !uidsSelected.includes(el.uid))
    //
    //   return unselected
    // })

    return {
      tags,
      state,
      // unselectedFields,
    }
  },
})
